import { Button, Col, Row, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import * as classes from '../OrderDetail.module.css';
import {
  MailOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { get, isEmpty } from "lodash";
import AttendantModal from "./AttendantModal/AttendantModal";
import { checkPermission } from "../../../../util/helpers";
import Pagination from "react-js-pagination";
import { ROLE_VALUES } from "../../../../util/constants";
import orderAPI from "../../../../api/OrderAPI/orderAPI";
import { getCurrentUser } from "../../../../util/auth";
import { toast } from "react-toastify";

const { Option } = Select;

const DEFAULT_ATTENDANT_PAGE_SIZE = 20;

const Attendants = (props) => {
  const code = props.match.params.code;
  const user = getCurrentUser();

  const [attendantsData, setAttendantsData] = useState({
    attendants: [],
    paging: null
  });

  const [isShowAttendantModal, setIsShowAttendantModal] = useState(false);
  const [selectedAttendant, setSelectedAttendant] = useState();

  const [searchType, setSearchType] = useState("phone");
  const [searchValue, setSearchValue] = useState("");
  const [submittedSearchValue, setSubmittedSearchValue] = useState({})

  const [attendantProcessingId, setAttendantProcessingId] = useState();
  
  const _handleResendEmail = async (participantId) => {
    setAttendantProcessingId(participantId); // loading
    await _resendEmailForAttendant(participantId);
    setAttendantProcessingId();
  }

  const getAttendants = async (page = 1) => {
    if (!code) {
      return;
    }
    props.setIsLoading(true);
    try {
      const queryObject = {
        ...submittedSearchValue,
        limit: DEFAULT_ATTENDANT_PAGE_SIZE
      }
      const queryString = new URLSearchParams(queryObject).toString()
      const response = await orderAPI.getAttendants(code, page, queryString);
      if (response) {
        setAttendantsData(response);
      }
    } catch (error) {
      setAttendantsData({
        attendants: [],
        paging: null    
      })
      console.log("Failed to fetch", error);
    }
    props.setIsLoading(false);
  }

  const _handleAttendantFieldChange = (key, value, participantId) => {
    if (isEmpty(participantId)) return;

    const updatedAttendants = [...attendantsData.attendants];
    const attendantIndex = updatedAttendants.findIndex(attendant => attendant.participant_id === participantId);
    
    if (attendantIndex === -1) return;

    updatedAttendants[attendantIndex][key] = value;
    
    setAttendantsData({
      ...attendantsData,
      attendants: updatedAttendants
    })
  }

  const _handleUpdateAttendantInfo = async (attendant) => {
    let data = {
      username: user.result.user_name,
      attendant: {
        blood_type: attendant.blood_type,
        email: attendant.email,
        phone: attendant.phone,
        phone_area_code: attendant.phone_area_code,
        dob: attendant.dob,
        emergency_contact_name: attendant.emergency_contact_name,
        emergency_contact_phone: attendant.emergency_contact_phone,
        emergency_contact_phone_area_code: attendant.emergency_contact_phone_area_code,
        emergency_contact_relationship: attendant.emergency_contact_relationship,
        first_name: attendant.first_name,
        last_name: attendant.last_name,
        gender: attendant.gender,
        id_card: attendant.id_card,
        nationality: attendant.nationality,
        t_shirt_size: attendant.t_shirt_size,
        name_on_bib: attendant.name_on_bib,
        allergy: attendant.allergy,
        medicine: attendant.medicine
      }
    }
    try {
      const response = await orderAPI.updateAttendantInfo(code, attendant.participant_id, data)
      if (response) {
        toast.success('Thông tin vận động viên được cập nhật thành công!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
        return response;
      }
    } catch (error) {
      const errorMessage = error.response.data.error?.message || 'Đã có lỗi xảy ra'
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
      console.log("Failed to fetch", error);
    }
  }

  const _resendEmailForAttendant = async (participantId) => {
    if (window.confirm("Bạn có chắc chắn muốn gửi email xác nhận cho vận động viên này ?")) {
      try {
        const submitData = {
          username: user.result.user_name
        };
        const response = await orderAPI.resendAttendantPaymentSuccessEmail(code, participantId, submitData)
        if (response) {
          toast.success('Email Sent Successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      } catch (error) {
        console.log("Failed to fetch", error);
      }      
    }
  }

  const _handleSubmitFilter = () => {
    setSubmittedSearchValue({
      [searchType]: searchValue
    });
  }

  useEffect(() => {
    getAttendants(1);
  }, [submittedSearchValue]);

  return (
    <Row>
      <Col className={`${classes.label} d-flex justify-content-between`} span={24}>
        Vận động viên
        <div>
          <Select
            className={classes.paymentMethod}
            style={{ width: 160 }}
            placeholder="Tìm kiếm theo"
            value={searchType || null}
            onChange={(selected) => setSearchType(selected)}
          >
            <Option value="phone">Số điện thoại</Option>
            <Option value="ticket_no">Mã tham chiếu</Option>
          </Select>
          <Input 
            size="medium"
            style={{width: 200, marginLeft: 4}}
            placeholder={`Tìm kiếm`}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Button
            className={classes.search}
            style={{marginLeft: 4}}
            type="primary"
            icon={<SearchOutlined />}
            onClick={_handleSubmitFilter}
          >
            Tìm kiếm
          </Button>
        </div>
      </Col>
      <Col span={24} className="mt-3">
        <Row justify="start" gutter={[16, 16]}>
          {attendantsData.attendants.map((attendant, index) => (
            <Col span={6} key={index}>
              <ul className="list-group">
                <li className="list-group-item d-flex align-items-center">
                  <img src="https://pix.raceez.com/2019/12/13/default-avatar.jpg" alt="Avatar" className={classes.avatar} />
                  <div 
                    className="d-flex flex-column" 
                    onClick={() => {
                      setSelectedAttendant(attendant);
                      setIsShowAttendantModal(true)  
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <p className="m-0 font-weight-bold">{attendant.first_name} {attendant.last_name}</p>
                    <p className="m-0">{get(attendant, 'phone_area_code', '') && `(+${get(attendant, 'phone_area_code', '')})`} {attendant.phone}</p>
                    <p className="m-0">
                      {get(attendant, "is_sent_email_success" , "1") === "1" ? (
                        <span style={{color: "#1890ff"}}>
                          (Đã gửi email)
                        </span>
                      ) : (
                        <span>(Chưa gửi email)</span>
                      )}
                    </p>
                  </div>
                  {(checkPermission('list', ROLE_VALUES.EDIT_PARTICIPANT_INFO) && !isEmpty(attendant.participant_id)) ? (
                    <Button 
                      loading={attendantProcessingId === attendant.participant_id}
                      icon={<MailOutlined />} 
                      className="ml-auto" 
                      style={{border: 0}} 
                      onClick={() => _handleResendEmail(attendant.participant_id)} 
                    />
                  ) : null}
                </li>
              </ul> 
            </Col>
          ))}
          <Col span={24} className="d-flex justify-content-end">
            {attendantsData.paging && attendantsData?.paging.total_item >= DEFAULT_ATTENDANT_PAGE_SIZE + 1 && (
              <Pagination
                pageRangeDisplayed={7}
                activePage={attendantsData.paging.current_page}
                itemsCountPerPage={DEFAULT_ATTENDANT_PAGE_SIZE}
                totalItemsCount={attendantsData.paging.total_item}
                onChange={(pageNumber) => getAttendants(pageNumber)}
                itemClass="page-item"
                linkClass="page-link"
                style={{
                  padding: 20,
                  textAlign: 'right',
                  width: '100%',
                }}
              />
            )}
          </Col>
        </Row>
      </Col>
      <AttendantModal 
        isOpen={isShowAttendantModal} 
        onCancel={() => setIsShowAttendantModal(false)} 
        attendant={selectedAttendant} 
        _handleAttendantFieldChange={(key, value, id) => _handleAttendantFieldChange(key, value, id)}
        _handleUpdateAttendantInfo={(attendant) => _handleUpdateAttendantInfo(attendant)}
      />
    </Row>
  )
}

export default Attendants;