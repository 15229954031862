import React, { useEffect } from "react";
import { Button, Col, Row, Select } from "antd";
import * as classes from '../OrderDetail.module.css';
import { checkPermission, currencyFormat } from "../../../../util/helpers";
import { get } from "lodash";
import RefundModal from "../RefundModal/RefundModal";
import { useState } from "react";
import { ROLE_VALUES } from "../../../../util/constants";

const { Option } = Select;
const OrderInfo = ({ orderDetail, _handOrderStatusChange, _submitRefundData, _handleChangePaymentMethod }) => {
  const [isShowRefundModal, setIsShowRefundModal] = useState(false);

  const isDisableOrderStatusChange =
    (orderDetail.transactions && orderDetail.transactions.length > 0) &&
    (orderDetail.order_status === "dat_hang_thanh_cong"
      || orderDetail.transactions[0].payment_method === "vnpay"
      || orderDetail.transactions[0].payment_method === "zalopay"
      || orderDetail.transactions[0].payment_method === "viet_qr"
      || orderDetail.transactions[0].payment_method === "shopeepay"
      || orderDetail.transactions[0].payment_method === "credit"
      || orderDetail.transactions[0].payment_method === "atm")
  return (
    <>
      <Row gutter={[16, 16]} className={classes.infoContainer} style={{ position: 'relative' }}>
        <Col span={24} className={classes.label}>Thông tin đơn hàng</Col>
        {(orderDetail.order_status === 'dat_hang_thanh_cong'
          && (orderDetail.transactions[0].payment_method === 'credit' || orderDetail.transactions[0].payment_method === 'atm')
          && checkPermission('list', ROLE_VALUES.REFUND))
          ? (
            <Button
              type="button"
              className="btn btn-primary mr-2"
              style={{ height: 40, color: '#1890ff', position: 'absolute', right: 0, top: 0, fontWeight: '600', fontSize: 16 }}
              onClick={() => setIsShowRefundModal(true)}
            >
              Hoàn tiền
            </Button>
          ) : null}

        <Col span={12}>
          Giá trị đơn hàng :
          <span className={classes.buyerLabel}> {currencyFormat(get(orderDetail, 'grand_total', 0))} </span>
        </Col>
        <Col span={12}>
          Thời gian mua hàng :
          <span className={classes.buyerLabel}> {orderDetail.created_at} </span>
        </Col>
        <Col span={12}>
          Trạng thái đơn hàng :
          {((!checkPermission('list', ROLE_VALUES.CHANGE_STATUS)))
            ?
            <span className={classes.buyerLabel}> {orderDetail.order_status_description_vi} </span>
            :
            <Select
              className={classes.buyerLabel}
              style={{ width: 280, paddingLeft: '5px' }}
              value={orderDetail.order_status}
              onChange={(e) => _handOrderStatusChange(e)}
            >
              <Option value="dat_hang_thanh_cong">Đặt hàng thành công</Option>
              {
                orderDetail.order_status_description_vi !== 'Đặt hàng thành công' &&
                <Option value="doi_thanh_toan">Đợi thanh toán</Option>
              }
              <Option value="hoan_tien">Hoàn tiền</Option>

              <Option value="da_huy">Thanh toán thất bại</Option>
            </Select>
          }
        </Col>
        <Col span={12}>
          Trạng thái thanh toán :
          <span className={classes.buyerLabel}> {orderDetail.payment_status_description_vi} </span>
        </Col>
        <Col span={12}>
          Hình thức thanh toán :
          {
            (orderDetail.order_status === 'dat_hang_thanh_cong')
              ?
              <span className={classes.buyerLabel}> {orderDetail.transactions[0].payment_method_name_vi}</span>
              :
              <Select
                className={classes.buyerLabel}
                style={{ width: 280, paddingLeft: '5px' }}
                value={(orderDetail.transactions && orderDetail.transactions.length > 0) && orderDetail.transactions[0].payment_method}
                onChange={(e) => _handleChangePaymentMethod(e)}
              >
                <Option value="credit">Thẻ tín dụng (Credit)</Option>
                <Option value="credit_global">Thẻ tín dụng phát hành ngoài VN</Option>
                <Option value="atm">Thẻ ngân hàng (ATM)</Option>
                <Option value="zalopay">ZaloPay</Option>
                <Option value="viet_qr">Viet QR</Option>
                <Option value="vnpay">QRCode</Option>
                <Option value="bank_transfer">Chuyển khoản ngân hàng</Option>
                <Option value="voucher">Voucher</Option>
                <Option value="momo">Momo</Option>
                <Option value="partner_vnpay">Partner Vnpay</Option>
                <Option value="vn_pay_b">VNPAY</Option>
              </Select>
          }
        </Col>
        <Col span={12}>
          Thông tin sản phẩm :
          <span className={classes.buyerLabel}> {orderDetail.item_description_vi}</span>
        </Col>
        <Col span={12}>
          Mã giao dịch :
          <span className={classes.buyerLabel}> {get(orderDetail, 'transactions[0].reference_number', '')}</span>
        </Col>
        <Col span={12}>
          Mã đơn hàng đối tác :
          <span className={classes.buyerLabel}> {get(orderDetail, 'extra.vm_order_id', '')}</span>
        </Col>
      </Row>
      {checkPermission('list', ROLE_VALUES.REFUND) ? (
        <RefundModal
          isOpen={isShowRefundModal}
          onCancel={() => setIsShowRefundModal(false)}
          _submitRefundData={(data) => _submitRefundData(data)}
        />
      ) : null}
    </>

  )
}

export default OrderInfo;